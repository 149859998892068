import React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { MinnaLogo } from 'minna-ui';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import type { Theme } from '@mui/material';
import { Button } from '@mui/material';
import { DescriptionOutlined } from '@mui/icons-material';

export interface AdminMenuBarProps {
  title: string;
}

const RightSide = styled.div`
  display: flex;
  align-items: end;
`;

const AppBarOnTop = styled(AppBar)`
  z-index: ${(props: { theme: Theme }) => props.theme.zIndex.drawer + 1};
  background-color: ${(props: { theme: Theme }) => props.theme.palette.primary.main};
`;

const UserGuideButton = styled(Button)`
  margin: 0 18px 6px 0;
  text-transform: none;
`;

export const ActionPortalMenuBar: React.FC<AdminMenuBarProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBarOnTop position="fixed" data-testid="menu-bar-top" theme={theme}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <RightSide>
            <UserGuideButton
              variant="contained"
              startIcon={<DescriptionOutlined />}
              href="https://minna.tech/hubfs/minna-action-portal-user-guide.pdf"
              // @ts-ignore
              target="_blank"
            >
              User Guide
            </UserGuideButton>
            <MinnaLogo />
          </RightSide>
        </Toolbar>
      </AppBarOnTop>
    </Box>
  );
};
