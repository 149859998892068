'use client';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ActionPortalMenuBar } from './ActionPortalMenuBar';

export const PageTitleContext = React.createContext(null);

export const useSetPageTitle = (title: string) => {
  const changePageTitle = React.useContext(PageTitleContext);

  useEffect(() => {
    changePageTitle(title);
  }, []);
};

const MainContentContainer = styled.main`
  margin-top: 70px;
  height: calc(100vh - 64px); //view height minus header
`;

interface AdminNavigationProps {
  children: React.ReactNode;
}

export const ActionPortalNavigation: React.FC<AdminNavigationProps> = ({ children }) => {
  const [title, setTitle] = React.useState('Minna Action Portal');

  const changePageTitle = (title: string) => {
    setTitle(title);
  };

  return (
    <>
      <ActionPortalMenuBar title={title} />
      <PageTitleContext.Provider value={changePageTitle}>
        <MainContentContainer>{children}</MainContentContainer>
      </PageTitleContext.Provider>
    </>
  );
};
