'use client';

import type { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { IntlErrorCode, NextIntlProvider } from 'next-intl';
import { Locale } from '@utils/models';

/**
 * This is a wrapper around NextIntlProvider to be used to render on the client.
 * The implementation must be changed when we decide to support server-side rendering.
 */
export const InternationalizationClientProvider = ({ children }: PropsWithChildren) => {
  const [localizationMessages, setLocalizationMessages] = useState<Record<string, Record<string, string>>>({});
  const [locale, setLocale] = useState<Locale>(Locale.English);

  function getMessageFallback({ namespace, key, error }) {
    const path = [namespace, key].filter((part) => part != null).join('.');

    if (error.code === IntlErrorCode.MISSING_MESSAGE) {
      return `${path} is not yet translated`;
    } else {
      return `Dear developer, please fix this message: ${path}`;
    }
  }

  useEffect(() => {
    const fetchLocalizationMessages = async () => {
      await fetch(`./localization/${locale}.json`)
        .then<Record<string, Record<string, string>>>(async (res) => res.json())
        .then(setLocalizationMessages);
    };

    fetchLocalizationMessages();
  }, [locale]);

  return (
    <NextIntlProvider locale={locale} messages={localizationMessages} getMessageFallback={getMessageFallback}>
      {children}
    </NextIntlProvider>
  );
};
