import { createTheme } from '@mui/material/styles';

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: '#3E223E',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#721E60',
    },
    action: {
      selected: '#3E223E14',
    },
    error: {
      main: '#B22C3C',
    },
    success: {
      main: '#2E7D32',
    },
    warning: {
      main: '#ED6C02',
    },
    text: {
      primary: '#000000DE',
      secondary: '#00000099',
    },
  },
  typography: {
    h6: {
      fontSize: '14px',
      fontWeight: 700,
    },
  },
});
