"use client";
import type { PropsWithChildren } from "react";
import React, { createContext, useCallback, useContext, useState } from "react";
import { ErrorDialogComponent } from "./ErrorDialogComponent";
import * as Sentry from "@sentry/nextjs";

export interface ErrorDialogValue {
  operationDescription: string;
  error: any;
}

export type ErrorDialogCreator = (
  operationDescription: string,
) => (error: any) => never;
export type ErrorDialogDispatch = (error: ErrorDialogValue) => void;

const ErrorDialogDispatchContext = createContext<ErrorDialogDispatch>(
  () => undefined,
);

/**
 * Context and renderer for the ErrorDialog. Components using the ErrorDialog context with the provided hooks (useErrorDialog*) must be part of the nested components.
 */
export const ErrorDialogContext: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [errorDialogValue, setErrorDialogValue] = useState<
    ErrorDialogValue | undefined
  >(undefined);
  return (
    <>
      <ErrorDialogDispatchContext.Provider value={setErrorDialogValue}>
        {children}
        {errorDialogValue && (
          <ErrorDialogComponent
            errorDialogValue={errorDialogValue}
            closeErrorDialog={() => setErrorDialogValue(undefined)}
          />
        )}
      </ErrorDialogDispatchContext.Provider>
    </>
  );
};

/**
 * Set the error that should be displayed in the ErrorDialog.
 */
export function useErrorDialogDispatch(): ErrorDialogDispatch {
  return useContext(ErrorDialogDispatchContext);
}

/**
 * Promise rejection handler to pass the error to the ErrorDialog.
 */
export function useErrorDialogCatch(): ErrorDialogCreator {
  const errorDialogDispatch = useContext(ErrorDialogDispatchContext);

  return useCallback(
    (operationDescription: string) => {
      return (error: any) => {
        errorDialogDispatch({
          operationDescription,
          error,
        });
        Sentry.captureException(error);
        throw error;
      };
    },
    [errorDialogDispatch],
  );
}
