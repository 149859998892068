import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import type { ErrorDialogValue } from './index';
import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ErrorDialogComponentProps {
  errorDialogValue: ErrorDialogValue;

  closeErrorDialog(): void;
}

export const ErrorDialogComponent: React.FC<ErrorDialogComponentProps> = ({ errorDialogValue, closeErrorDialog }) => {
  const errorComponent = errorDialogValue.error.isAxiosError ? (
    <AxiosErrorDisplay error={errorDialogValue.error as AxiosError} />
  ) : (
    <GenericErrorDisplay error={errorDialogValue.error} />
  );

  return (
    <Dialog open TransitionComponent={Transition} keepMounted onClose={closeErrorDialog}>
      <DialogTitle>An error occurred</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            Operation{' '}
            <strong>
              {'"'}
              {errorDialogValue.operationDescription}
              {'"'}
            </strong>{' '}
            failed with error:
          </Typography>
          <Typography variant="body1">{errorDialogValue.error.message}</Typography>

          {errorComponent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeErrorDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

interface AxiosErrorDisplayProps {
  error: AxiosError;
}

function parseErrorMessage(responseData: string): string | undefined {
  try {
    const errorMessage = JSON.parse(responseData).error?.message;
    if (typeof errorMessage === 'string') {
      return errorMessage;
    }
  } catch (error) {
    // ignore
  }
  return undefined;
}

export const AxiosErrorDisplay: React.FC<AxiosErrorDisplayProps> = ({ error }) => {
  const method = (error.config?.method || '').toUpperCase();
  const url = error.request?.responseURL;
  const responseData =
    typeof error.response?.data === 'string'
      ? error.response?.data
      : JSON.stringify(error.response?.data, undefined, 2);

  const errorMessage = parseErrorMessage(responseData);
  return (
    <>
      {Boolean(errorMessage) && (
        <div>
          <Typography variant="h5">Error message</Typography>
          <Typography variant="body1">{errorMessage}</Typography>
        </div>
      )}
      <div>
        <Typography variant="h5">HTTP request</Typography>
        <Typography variant="body1">
          <b>{method}</b> {url}
        </Typography>
      </div>
      <div>
        <Typography variant="h5">HTTP response</Typography>
        <Typography variant="body1">
          <b>Status:</b> {error.response?.status}
        </Typography>
        <Typography variant="body1">
          <b>Data:</b>
        </Typography>
        <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
          {responseData}
        </Typography>
      </div>
    </>
  );
};

interface GenericErrorDisplayProps {
  error: any;
}

export const GenericErrorDisplay: React.FC<GenericErrorDisplayProps> = ({ error }) => {
  return (
    <>
      {error.description && (
        <p>
          <Typography variant="h5">Description</Typography>
          <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
            {error.description}
          </Typography>
        </p>
      )}
      <p>
        <Typography variant="h5">Content</Typography>
        {error.name && <Typography variant="body1">{error.name}</Typography>}
        <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
          {error.message}
        </Typography>
      </p>
    </>
  );
};
