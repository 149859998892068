export const MinnaLogo = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_338_13652)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5701 18.1007L32.5789 32.7761H15.3305L15.3261 18.1503L12 14.9032V35.9642H35.9099V14.9032L32.5701 18.1007Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6479 12L23.8992 15.5167L20.1509 12L18.4538 13.8594L23.8992 18.9677L29.3445 13.8594L27.6479 12Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_338_13652">
        <rect width="24" height="24" fill="white" transform="translate(12 12)" />
      </clipPath>
    </defs>
  </svg>
);
